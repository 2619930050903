<script setup lang="ts">
import { MoonIcon, SunIcon } from 'lucide-vue-next'

const colorMode = useColorMode()
</script>

<template>
  <Button
    variant="ghost"
    size="icon"
    @click="
      colorMode.preference = colorMode.value === 'light' ? 'dark' : 'light'
    "
  >
    <component
      :is="colorMode.value === 'light' ? SunIcon : MoonIcon"
      class="size-4"
    />
  </Button>
</template>
